import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/Auth/auth.service';
import { LocationStrategy } from '@angular/common';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
	emailModel = '';
	passwordModel = '';
	buttonDisabled = false;
    buttonState = '';
    loginForm;
    json:any;

	constructor(
    private authService: AuthService,
	private router: Router,
	private location: LocationStrategy,
  private spinner: NgxSpinnerService
	) {
		history.pushState(null, null, window.location.href);
		this.location.onPopState(() => {
		  history.pushState(null, null, window.location.href);
		});
	}

  ngOnInit() {}

  ngAfterViewInit() {

  }

  onLoggedin() {
	localStorage.setItem('isLoggedin', 'true');

	var email= document.getElementById('userName')as HTMLInputElement;
	var password= document.getElementById('password')as HTMLInputElement;

	if(email.value ==null ||email.value==""){
		alert("Email is empty");
	}if(password.value ==null ||password.value==""){
		alert("Password is empty");
	}else{

		this.emailModel = email.value;
		this.passwordModel = password.value;

	this.authService.SignIn(this.emailModel,this.passwordModel).subscribe((response) => {
		console.log(response);


				if (response.access_token != null) {
					console.log('logged success');
					console.log('setting up tokens ');
					localStorage.setItem('accessToken', response.access_token);
					localStorage.setItem('refreshToken', response.refresh_token);
					localStorage.setItem('email',email.value);
					console.log('redirecting to login root ');

					var res = email.value.split("@");
					localStorage.setItem('tenant', res[1]);

					this.router.navigate([ './dashboard/dashboard1']);
				}else{
					alert("Loggin Error");
				}
	});
	}
  }
}
