<div class="login-register" style="background-image:url(assets/images/background/tea-bg.jpg);">
  <div class="header row">
    <div class="col-md-4 header-logo">
        <img class="logo" src="./assets/images/logo2.png" alt="">
    </div>
    <div class="col-md-4">
      <h1>Tea Valuation Portal</h1>
    </div>

  </div>
  <div class="row">
    <div class="col-md-6 login-text" style="margin: auto;">
        <!-- <h2>Welcome Note</h2> -->
    </div>
    <div class="col-md-6">
      <div class="login-box card"  style="margin-top: 100px;background: #ffffff82;">
        <div class="card-body">
          <form class="form-horizontal form-material" id="loginform">
            <h3 class="box-title m-b-20" style="color: #000;text-align: center;">Sign In</h3>
            <div class="form-group ">
              <div class="col-xs-12">
                <input class="form-control" type="text" required="" placeholder="Email" id="userName"> </div>
            </div>
            <div class="form-group m-b-0">
              <div class="col-xs-12">
                <input class="form-control" type="password" required="" placeholder="Password" id="password"> </div>
            </div>
            <div class="form-group row ">
              <div class="col-md-12 font-14" style="text-align: center;">
                <div class="checkbox checkbox-primary pull-left p-t-0">
                  <!-- <input id="checkbox-signup" type="checkbox">
                  <label for="checkbox-signup"> Remember me </label> -->
                </div>
                <!-- <a href="javascript:void(0)" id="to-recover" class="text-dark ">
                  <i class="fa fa-lock m-r-5"></i> Forgot pwd?</a> -->
              </div>
            </div>
            <div class="form-group text-center m-t-20 m-b-0">
              <div class="col-xs-12">
                <a class="btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light"
                  (click)="onLoggedin()" style="background: #00862b;color: white;">Log In</a>
              </div>
            </div>

            <div class="form-group m-b-0">
              <div class="col-sm-12 text-center">
                <!-- <div>Don't have an account?
                  <a class="text-info m-l-5" [routerLink]="['/authentication/signup']">
                    <b>Sign Up</b>
                  </a>
                </div> -->
              </div>
            </div>
          </form>
          <form class="form-horizontal" id="recoverform" action="index.html">
            <div class="form-group ">
              <div class="col-xs-12">
                <h3>Recover Password</h3>
                <p class="text-muted">Enter your Email and instructions will be sent to you! </p>
              </div>
            </div>
            <div class="form-group ">
              <div class="col-xs-12">
                <input class="form-control" type="text" required="" placeholder="Email"> </div>
            </div>
            <div class="form-group text-center m-t-20">
              <div class="col-xs-12">
                <a class="btn btn-primary btn-lg btn-block text-uppercase waves-effect waves-light" [routerLink]="['/login']">Reset</a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="footer-login row">
    <div class="col-md-2">
      <img class="cicra-logo" src="./assets/images/cicra-solutions-logo.png" alt="">
    </div>
    <div class="col-md-10 footer-copyright">
        <p>Copyright © 2021 CICRA Solutions</p>
    </div>
    </div>
</div>
