import { SyncComponent } from './../../form/sync-catalogue/sync-catalogue.component';
import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
    // {
    //     path: '',
    //     title: 'Personal',
    //     icon: '',
    //     class: 'nav-small-cap',
    //     label: '',
    //     labelClass: '',
    //     extralink: true,
    //     submenu: []
    // },
    {
        path: '/dashboards/dashboard1',
        title: 'Dashboards',
        icon: 'mdi mdi-gauge',
        class: 'has-arrow',
        label: '',
        labelClass: 'label label-rouded label-themecolor',
        extralink: false,
        submenu: [
            {
                path: '/dashboard/dashboard1',
                title: 'Dashboard',
                icon: '',
                class: '',
                label: '',
                labelClass: '',
                extralink: false,
                submenu: []
            },
            // {
            //     path: '/dashboard/dashboard2',
            //     title: 'Classic',
            //     icon: '',
            //     class: '',
            //     label: '',
            //     labelClass: '',
            //     extralink: false,
            //     submenu: []
            // },
            // {
            //     path: '/dashboard/dashboard3',
            //     title: 'Analytical',
            //     icon: '',
            //     class: '',
            //     label: '',
            //     labelClass: '',
            //     extralink: false,
            //     submenu: []
            // }
        ]
    },
//     {
//       path: '',
//       title: 'Master Data',
//       icon: 'mdi mdi-file-document',
//       class: 'has-arrow',
//       label: '',
//       labelClass: 'label label-rouded label-themecolor',
//       extralink: false,
//       submenu: [
//           {
//                           path: '/forms/broker',
//                           title: 'Broker',
//                           icon: '',
//                           class: '',
//                           label: '',
//                           labelClass: '',
//                           extralink: false,
//                           submenu: []
//                       },
//                       {
//                         path: '/forms/cat',
//                         title: 'Catalogue',
//                         icon: '',
//                         class: '',
//                         label: '',
//                         labelClass: '',
//                         extralink: false,
//                         submenu: []
//                     },

//                     {
//                         path: '/forms/sales',
//                         title: 'Sales Year & No',
//                         icon: '',
//                         class: '',
//                         label: '',
//                         labelClass: '',
//                         extralink: false,
//                         submenu: []
//                     },


//       ]
//   },

  {
    path: '',
    title: 'Broker',
    icon: 'mdi mdi-cloud-upload',
    class: 'has-arrow',
    label: '',
    labelClass: 'label label-rouded label-themecolor',
    extralink: false,
    submenu: [
        {
            path: '/forms/brokershortcodes',
            title: 'Broker Short Codes',
            icon: '',
            class: '',
            label: '',
            labelClass: '',
            extralink: false,
            submenu: []
        },
        {
                                    path: '/forms/brokerLots',
                                    title: 'Upload Catalogue',
                                    icon: '',
                                    class: '',
                                    label: '',
                                    labelClass: '',
                                    extralink: false,
                                    submenu: []
          },

          {
            path: '/forms/confirm',
            title: 'Confirm Valuation',
            icon: '',
            class: '',
            label: '',
            labelClass: '',
            extralink: false,
            submenu: []
          },

          {
            path: '/forms/syncCatalogue',
            title: 'Sync Catalogue',
            icon: '',
            class: '',
            label: '',
            labelClass: '',
            extralink: false,
            submenu: []
          },

    ]
},

// {
//     path: '',
//     title: 'Buyer',
//     icon: 'mdi mdi-cloud-upload',
//     class: 'has-arrow',
//     label: '',
//     labelClass: 'label label-rouded label-themecolor',
//     extralink: false,
//     submenu: [
//         {
//             path: '/forms/buyershortcodes',
//             title: 'Buyer Short Codes',
//             icon: '',
//             class: '',
//             label: '',
//             labelClass: '',
//             extralink: false,
//             submenu: []
//         },
//           {
//             path: '/forms/lots',
//             title: 'Lots',
//             icon: '',
//             class: '',
//             label: '',
//             labelClass: '',
//             extralink: false,
//             submenu: []
//         },

//         // {
//         //     path: '/forms/viewLots',
//         //     title: 'View Lots',
//         //     icon: '',
//         //     class: '',
//         //     label: '',
//         //     labelClass: '',
//         //     extralink: false,
//         //     submenu: []
//         // },

//     ]
// },






];
