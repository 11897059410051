<!-- User profile -->
<div class="user-profile">
    <!-- User profile image -->
    <div class="profile-img">
        <img src="assets/images/users/user.png" alt="user" />
        <!-- this is blinking heartbit-->
        <div class="notify setpos">
            <span class="heartbit"></span>
            <span class="point"></span>
        </div>
    </div>
    <!-- User profile text-->
    <div class="profile-text">
        <h5>{{email}}</h5>
        <!-- <a ngbDropdownToggle ngbDropdown href="javascript:void(0)" class="u-dropdown">
            <i class="mdi mdi-settings"></i>
            <div class="dropdown-menu animated flipInY" ngbDropdownMenu>
                <a href="#" class="dropdown-item">
                    <i class="ti-user"></i> My Profile</a>
              
                <div class="dropdown-divider"></div>
                <a href="#" class="dropdown-item">
                    <i class="ti-settings"></i> Setting</a>
                <div class="dropdown-divider"></div>
                <a href="#" class="dropdown-item">
                    <i class="fa fa-power-off"></i> Logout</a>
            </div>
        </a>
   
        <a href="#" class="" data-toggle="tooltip" title="Logout">
            <i class="mdi mdi-power"></i>
        </a> -->
    </div>
</div>
<!-- End User profile text-->
<li class="nav-devider"></li>
<!-- Sidebar navigation-->
<nav class="sidebar-nav">
    <ul id="sidebarnav">
        <!-- First level menu -->
        <li [class.active]="showMenu === sidebarnavItem.title" *ngFor="let sidebarnavItem of sidebarnavItems" [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">
            <div class="nav-small-cap" *ngIf="sidebarnavItem.extralink === true">{{sidebarnavItem.title}}</div>
            <a [routerLink]="sidebarnavItem.class === '' ? [sidebarnavItem.path] : null" [ngClass]="[sidebarnavItem.class]" *ngIf="!sidebarnavItem.extralink;"
                (click)="addExpandClass(sidebarnavItem.title)">
                <i [ngClass]="[sidebarnavItem.icon]"></i>
                <span class="hide-menu">{{sidebarnavItem.title}}
                    <span *ngIf="sidebarnavItem.label != '' " [ngClass]="[sidebarnavItem.labelClass]">{{sidebarnavItem.label}}</span>
                </span>
            </a>
            <!-- Second level menu -->
            <ul class="collapse" *ngIf="sidebarnavItem.submenu.length > 0" [ngClass]="{'in' : showMenu === sidebarnavItem.title }">
                <li *ngFor="let sidebarnavSubItem of sidebarnavItem.submenu" [class.active]="showSubMenu === sidebarnavSubItem.title" [routerLinkActive]="sidebarnavSubItem.submenu.length > 0 ? '' : 'active'">
                    <a [routerLink]="sidebarnavSubItem.submenu.length > 0 ? null : [sidebarnavSubItem.path]" [routerLinkActive]="sidebarnavSubItem.submenu.length > 0 ? '' : 'router-link-active'"
                        [ngClass]="[sidebarnavSubItem.class]" *ngIf="!sidebarnavSubItem.extralink;" (click)="addActiveClass(sidebarnavSubItem.title)">
                        <i [ngClass]="[sidebarnavSubItem.icon]"></i>
                        {{sidebarnavSubItem.title}}
                    </a>
                    <!-- Third level menu -->
                    <ul class="collapse" *ngIf="sidebarnavSubItem.submenu.length > 0" [ngClass]="{'in' : showSubMenu === sidebarnavSubItem.title }">
                        <li *ngFor="let sidebarnavSubsubItem of sidebarnavSubItem.submenu" routerLinkActive="active" [ngClass]="[sidebarnavSubsubItem.class]">
                            <a [routerLink]="[sidebarnavSubsubItem.path]" *ngIf="!sidebarnavSubsubItem.extralink;" [routerLinkActive]="sidebarnavSubsubItem.submenu.length > 0 ? '' : 'router-link-active'">
                                <i [ngClass]="[sidebarnavSubsubItem.icon]"></i>
                                {{sidebarnavSubsubItem.title}}
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>
    </ul>
</nav>
<!-- End Sidebar navigation -->