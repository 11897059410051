import { Injectable } from '@angular/core';
import { Observable, from, of, BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

export interface ISignInCredentials {
	email: string;
	password: string;
}

export interface User {
	email: string;
	password: string;
	displayName: string;
}

export interface ICreateCredentials {
	email: string;
	password: string;
	displayName: string;
}

export interface IPasswordReset {
	code: string;
	newPassword: string;
}



@Injectable({
  providedIn: 'root'
})



export class AuthService {
	constructor(private http: HttpClient) {}
	private currentUserSubject: BehaviorSubject<User>;
	currentUser;

	SignIn(email,password){
		var authData =  window.btoa(environment.clientId + ':' + environment.clientPw);
		const headers = new HttpHeaders().set('Authorization', 'Basic ' + authData);
		let options = {headers: headers };
		let data ={
			"grant_type":"password",
			"username":email,
			"password":password
		}
		let body = new FormData();
		body.append('grant_type','password');
		body.append('username',email);
		body.append('password',password);


		var url =
			environment.apiEndpoint+'oauth/token';


			//return this.http.post(url, options);

			return this.http.post<any>(url, body,options).pipe(
				map((res) => {
					console.log('returning token');
					console.log(res);
					return res;
				}),
				catchError(this.handleError<any>('login failier'))
			);

	 }




	// signIn(credentials: ISignInCredentials) {
	// 	console.log('calling access token in angular service ');
	// 	console.log(credentials);
	// 	var url =
	// 		environment.apiEndpoint +
	// 		'oauth/token?grant_type=password&username=' +
	// 		credentials.email +
	// 		'&password=' +
	// 		credentials.password;

	// 		console.log(url);
	// 		console.log(environment.clientPw);
	// 	var authData = window.btoa(environment.clientId + ':' + environment.clientPw);



	// 	const headers = new HttpHeaders().set('Authorization', 'Basic ' + authData);
	// 	// .set('Authorization', 'Basic Y2ljcmEtY3MtdmFsdWF0aW9uLWNsaWVudDpjaWNyYS1jYS12YWx1YXRpb24tc2VjcmV0');
	// 	return this.auth.post<any>(url, credentials, { headers: headers }).pipe(
	// 		map((res) => {
	// 			console.log('returning token');
	// 			console.log(res);
	// 			return res;
	// 		}),
	// 		catchError(this.handleError<any>('login failier'))
	// 	);
	// }

	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			// TODO: send the error to remote logging infrastructure
			// console.error(error); // log to console instead
			// TODO: better job of transforming error for user consumption
			console.log(`${operation} failed: ${error.message}`);

			// Let the app keep running by returning an empty result.
			return of(result as T);
		};
	}
	// signIn(credentials: ISignInCredentials) {
	// 	return this.auth.signInWithEmailAndPassword(credentials.email, credentials.password).then(({ user }) => {
	// 		return user;
	// 	});
	// }

	signOut() {
		localStorage.removeItem('accessToken');
		localStorage.removeItem('refreshToken');
		return true;
	}

	register(credentials: ICreateCredentials) {
		// return this
		// 	.createUserWithEmailAndPassword(credentials.email, credentials.password)
		// 	.then(async ({ user }) => {
		// 		user.updateProfile({ displayName: credentials.displayName });
		// 		this.auth.updateCurrentUser(user);
		// 		return user;
		// 	});
		return {};
	}

	sendPasswordEmail(email) {
		return {};
	}

	resetPassword(credentials: IPasswordReset) {
		return {};
	}

	async getUser() {
		return await this.currentUser;
	}
}
